import { useEffect } from 'react';
import Handlebars from 'handlebars';
import data from './../data/menu.json';

function sort(a, b) {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    return 0;
}

export default function Menu() {
    useEffect(() => {
        document.title = 'Our Menu';
        document.querySelector('#showModal').click();
    });

    data.menu.ulam.items.sort(sort);
    data.menu.merienda.items.sort(sort);
    data.menu.others.items.sort(sort);

    const template = Handlebars.compile(
        `<div class="container py-5 shadow-lg rounded custom-bg-colour1">
            <button type="button" id="showModal" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#announcement-modal"></button>
            <div class="modal fade" id="announcement-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-3 mx-auto text-danger-emphasis" id="staticBackdropLabel">Notice</h1>
                        </div>
                        <div class="modal-body">
                            <p class="text-center">An additional <span class="fw-bold">$5.00</span> will be charged for <span class="fw-bold">deliveries</span>.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-10 mx-auto col-12 text-center mb-3">
                    <h1 class="display-1 text-custom text-uppercase custom-font">Our Menu</h1>
                </div>
            </div>

            {{#each menu}}
            <hr>
            <div class="row py-5 px-3" style="column-count: 2;">
                <div class="sticky-top py-3 custom-bg-colour1">
                    <div class="col-lg-10 mx-auto col-12 text-center">
                        <h3 class="display-5 text-custom text-uppercase custom-font">{{this.title}}</h3>
                    </div>

                    {{#if this.subtitle}}
                    <div class="col-lg-10 mx-auto col-12 text-center">
                        <h2 class="text-custom text-uppercase custom-font">({{this.subtitle}})</h2>
                    </div>
                    {{/if}}
                </div>

                <div class="mb-2">&nbsp;</div>

                {{#each this.items}}
                <div class="card card-body col-sm-5 mx-2 my-2 shadow-sm custom-bg-colour2">
                    <div class="row">
                        <div class="col-sm-10">
                            <h6 class="my-auto text-uppercase fw-semibold custom-font">
                                {{this.name}}
                                {{#if this.subtitle}}
                                    <span class="small text-uppercase fw-light">({{this.subtitle}})</span>
                                {{/if}}
                            </h6>
                        </div>
                        <div class="col-sm-2 my-auto text-nowrap text-end custom-font text-custom">\${{this.price}}.00</div>
                    </div>
                </div>
                {{/each}}
            </div>
            {{/each}}

            <hr>
            <div class="row py-3 px-3">
                <div class="col">
                    <div class="text-center h1 text-danger">Notice</div>
                    <p class="text-center">An additional <span class="fw-bold">$5.00</span> will be charged for <span class="fw-bold">deliveries</span>.</p>
                </div>
            </div>
        </div>`
    );

    return (
        <div dangerouslySetInnerHTML={{ __html: template(data) }} />
    );
}